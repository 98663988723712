/* c8 ignore start */
import { createSSRApp } from 'vue';
import App from './MobilePage.vue';
// import { createRouter } from './router';

// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
export default function createApp(props) {
  const app = createSSRApp(App, props);
  // const router = createRouter();
  // app.use(router);
  return { app };
}
