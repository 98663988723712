import '@rei/cedar/dist/cdr-fonts.css';
import '@rei/cedar/dist/cdr-reset.css';
import '../../../style/common/landing-site-base.scss';

import '@rei/cedar/dist/style/cdr-img.css';
import '@rei/cedar/dist/style/cdr-text.css';
import '@rei/cedar/dist/style/cdr-grid.css';

import '@rei/landing-app-store-buttons/dist/style.css';

import createApp from './main';
import getClientPageData from '../../utils/clientPageData';

const { pageData: props } = getClientPageData();

const { app } = createApp(props);
app.mount('#app');
