<script>
import LandingAppStoreButtons from '@rei/landing-app-store-buttons';
import {
  CdrGrid,
  CdrImg,
  CdrText,
} from '@rei/cedar';
import analytics from '../../common/mixins/analytics';

export default {
  name: 'MobilePage',
  components: {
    CdrText,
    CdrImg,
    CdrGrid,
    LandingAppStoreButtons,
  },
  mixins: [analytics],
  props: {
    title: { type: String, default: 'REI Mobile App' },
  },
  computed: {
    renderingMap() {
      return {
        lead: true,
      };
    },
    pageSpecificViewAnalytics() {
      return {
        pageName: 'rei:landing page:mobile',
        templateType: 'landing',
      };
    },
  },
};
</script>
<template>
  <div
    v-if="renderingMap.lead"
    :data-location-name="analyticsMap.lead"
    class="mobile-page"
  >
    <cdr-text
      v-if="title"
      tag="h1"
      class="mobile-page__heading"
      data-ui="mobile-title"
    >
      {{ title }}
    </cdr-text>

    <cdr-grid class="mobile-page__grid">
      <cdr-img
        class="mobile-page__column-one"
        src="/assets/mobile/rei-mobile-app/live.jpg"
        alt="Mobile Device"
        data-ui="mobile-img"
      />

      <div class="mobile-page__column-two">
        <cdr-text
          class="mobile-page__body"
          data-ui="mobile-body"
        >
          <strong>
            Shop REI from (almost) anywhere.
          </strong>
          Use the REI Shopping App to browse,
          check in-store availability and buy top-brand outdoor gear and apparel,
          get deals in the REI Outlet and even build your wish list.
          Save your checkout details for quick, secure ordering.
          Plus, select the delivery method - including free same-day pickup when available.
        </cdr-text>

        <landing-app-store-buttons
          class="mobile-page__app-buttons"
          data-ui="mobile-app-buttons"
        />
      </div>
    </cdr-grid>
  </div>
</template>

<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

.mobile-page {
  @include cdr-container;
  margin-bottom: $cdr-space-two-x;

  &__heading {
    @include cdr-text-heading-serif-strong-800;
    margin: $cdr-space-one-x 0;
    text-align: center;
  }

  &__grid {
    grid-template-columns: 1fr 1fr;
    margin-top: $cdr-space-two-x;

    @include cdr-xs-mq-only {
      grid-template-columns: 1fr;
    }
  }

  &__body {
    margin-bottom: $cdr-space-one-x;
  }

  &__column-two {
    align-self: center;
  }

  &__app-buttons {
    justify-content: start;
  }
}
</style>
